<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="page_head">
      <el-breadcrumb-item :to="{ path: '/Master' }"
        >竞赛管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>邮寄地址管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="container-box" style="margin-top: 0.2rem">
      <p style="margin-bottom: 0.2rem"><b>证书邮寄地址</b></p>
      <table>
        <thead></thead>
        <tbody class="m-body">
          <tr v-if="user_id">
            <td class="m-empty mpty mpColor">邮寄地址登记人</td>
            <td class="m-empty mpty">
              <el-input
                v-model="submitter"
                placeholder="邮寄地址登记人"
                readonly
              />
            </td>
            <td class="m-empty mpty mpColor">登记人联系方式</td>
            <td class="m-empty mpty" style="position: relative">
              <el-input
                v-model="submitterPhone"
                placeholder="登记人联系方式"
                readonly
              />
            </td>
          </tr>

          <tr>
            <td class="m-empty mpty mpColor">
              <span class="iconVerify">*</span>收件人姓名
            </td>
            <td class="m-empty mpty">
              <el-input
                v-model="this.formObj.recipientName"
                placeholder="收件人姓名"
                :readonly="toEdit"
                clearable
              />
            </td>
            <td class="m-empty mpty mpColor">
              <span class="iconVerify">*</span>收件人电话
            </td>
            <td class="m-empty mpty" style="position: relative">
              <el-input
                type="number"
                v-model="this.formObj.recipientPhone"
                placeholder="收件人电话"
                :readonly="toEdit"
                clearable
              />
            </td>
          </tr>

          <tr>
            <td class="m-empty mpColor">
              <span class="iconVerify">*</span>收件详细地址
            </td>
            <td class="m-empty" colspan="3">
              <el-input
                v-model="this.formObj.address"
                placeholder="收件详细地址"
                :readonly="toEdit"
                clearable
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="saveBox marginBox">
        <div class="marginBox1" style="float: left">
          注：
          <span class="p2">1.如不填写邮寄信息，则默认不邮寄证书。</span>
          <p class="p1 p2">2.省赛证书与国赛证书都会邮寄到此登记地址。</p>
          <p class="p1 p2" style="color: red">
            3.每所学校只对应一个证书邮寄地址，建议填写校内方便证书分发。
          </p>
          <p class="p1 p2" style="color: red">
            4.如已有教师完成登记，其他教师不能进行修改，可联系登记人或收件人协商，登录平台修改地址。
          </p>
          <p class="p1 p2" style="color: red">
            5.请在&nbsp;<span class="updateTime">{{ deadline }}</span
            >&nbsp;前完成修改。
          </p>
        </div>
        <div style="float: right">
          <el-button
            type="primary"
            :color="btnColor"
            :disabled="disabled"
            @click="saveDatas"
            v-loading="loading"
            >保存并提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from "element-plus";
import { userInfo } from "api/apis.js";

export default {
  name: "CertificateMailing",
  components: {},

  data() {
    return {
      btnColor: "#436E8A",
      formObj: {
        recipientName: "",
        recipientPhone: "",
        address: "",
      },
      submitter: "",
      submitterPhone: "",
      loading: false,
      toEdit: true,
      disabled: false,
      user_id: "",
      deadline: "",
    };
  },
  watch: {},
  created() {
    this.init();
  },
  computed: {
    phoneRegex() {
      return /^1[3456789]\d{9}$/;
    },
  },
  methods: {
    paramsValidate: function (params) {
      let flag = true;

      for (var key in params) {
        if (params[key] != "0" && !params[key]) {
          ElMessage({
            message: "请完善数据!",
            type: "error",
          });
          return false; // 终止程序
        }
      }

      return flag;
    },
    init() {
      let uid = this.$store.state.User.user_id;
      let dataobj = new FormData();
      dataobj.append("user_id", uid);
      userInfo.find_address(dataobj).then((res) => {
        this.$nextTick(function () {
          // this.toEdit = res.address_mail_exist
          // this.disabled = res.address_mail_exist
          this.toEdit = !res.address_update;
          this.disabled = !res.address_update;
        });
        var data = JSON.parse(res.data);
        if (data && data.receiver_name) {
          this.formObj.recipientName = data.receiver_name;
        }
        if (data && data.receiver_phone) {
          this.formObj.recipientPhone = data.receiver_phone;
        }
        if (data && data.receiver_address) {
          this.formObj.address = data.receiver_address;
        }
        if (data && data.real_name) {
          this.submitter = data.real_name;
        }
        if (data && data.telephone) {
          this.submitterPhone = data.telephone;
        }
        if (data && data.submit_user_id) {
          this.user_id = data.submit_user_id;
        }
        if (res.update_time) {
          this.deadline = res.update_time[1];
        }
      });
    },
    //保存
    saveDatas() {
      var isFlag = this.paramsValidate(this.formObj);
      if (!isFlag) {
        return;
      } else {
      }
      var value = this.formObj.recipientPhone;
      if (!this.phoneRegex.test(value)) {
        ElMessage({
          message: "请输入有效的电话号码",
          type: "error",
        });
        return;
      } else {
      }
      this.loading = true;
      let uid = this.$store.state.User.user_id;
      let dataobj = new FormData();
      dataobj.append("user_id", uid);
      dataobj.append("receiver_name", this.formObj.recipientName);
      dataobj.append("receiver_phone", this.formObj.recipientPhone);
      dataobj.append("receiver_address", this.formObj.address);
      userInfo.mailing_address(dataobj).then((res) => {
        this.loading = false;
        this.disabled = true;
        this.init();
        if (res.code === 200) {
          ElMessage({
            message: "保存成功！",
            type: "success",
          });
        } else {
          ElMessage({
            message: "保存失败！",
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
<style lang="scss" scoped>
.tip {
  position: absolute;
  top: 0.5rem;
  right: 1.1rem;
  color: #f56c6c;
}
.page_head {
  margin-left: 0.4rem;
  margin-top: 0.2rem;
}
.p1 {
  text-indent: 0.39rem;
}
span {
  display: inline-block;
}
.p2 {
  margin-bottom: 0.05rem;
}
.iconVerify {
  display: inline-block;
  color: #f56c6c;
  margin-right: 0.04rem;
}
.container-box {
  width: 95%;
  height: 7rem;
  margin: auto;
  background: white;
  padding: 10px;
  table {
    table-layout: fixed;
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    tbody tr {
      transition: background 0.3s;
      .m-empty {
        // color: rgba(0, 0, 0, 0.25);
        font-size: 0.14rem;
        font-weight: bold;
        color: #606266;
        text-align: center;
        background: #fff;
        border-bottom: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        border-radius: 0 0 2px 2px;
        // .u-empty-icon {
        //     width: 64px;
        //     height: 41px;
        //     margin-bottom: 8px;
        // }
      }
      .mpty {
        border-top: 1px solid #e8e8e8;
      }
      .mpColor {
        background-color: #f5f7fa;
      }
      td {
        padding: 0.08rem;
        // border-bottom: 1px solid #e8e8e8;
        transition: background 0.3s;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
      &:hover {
        background: #e6f7ff;
      }
    }
  }
  .marginBox {
    margin-top: 0.2rem;
  }
}
.marginBox1 {
  font-size: 0.17rem;
  .updateTime {
    font-size: 0.18rem;
    font-weight: bold;
    display: inline;
  }
}
</style>